import './index.css';
import uts from '@linecorp/uts';

uts.init(
  {
    utsId: 'line_liff',
    appName: 'liff_lp',
    appEnv: process.env.UTS_APP_ENV,
  },
  { endpoint: process.env.UTS_ENDPOINT },
);

// ref: http://nagix.hatenablog.com/entry/2018/05/05/041949
let passiveSupported = false;
try {
  document.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get() {
        passiveSupported = true;
      },
    }),
  );
} catch (err) {
  // do nothing
}

document.addEventListener(
  'touchstart',
  function listener(e) {
    if (
      'ontouchstart' in document &&
      e instanceof TouchEvent &&
      e.touches.length >= 2
    ) {
      e.preventDefault();
    }
  },
  passiveSupported ? { passive: false } : false,
);
document.addEventListener(
  'touchmove',
  function listener(e) {
    if (
      'ontouchstart' in document &&
      e instanceof TouchEvent &&
      e.touches.length >= 2
    ) {
      e.preventDefault();
    }
  },
  passiveSupported ? { passive: false } : false,
);
